<template>
  <v-card width="500">
    <v-system-bar color="info">
      Import <v-spacer />
      <template v-if="data.running">Suche läuft!</template>
      <template v-else>
        Suche: {{ formatDate(data.startDate) }}, {{ data.startTime }}
        <template v-if="data.startedBy">({{ data.startedBy.code }})</template>
      </template>
    </v-system-bar>

    <v-data-table
      :headers="headers"
      :items="data.log"
      :items-per-page="-1"
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.date="{ item }">
        {{ formatDateShort(item.date) }}
      </template>
      <template v-slot:item.level="{ item }">
        <v-chip :color="getChipColor(item.level)">{{ item.level }}</v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "EventoImport",
  props: { data: {} },
  computed: {
    headers() {
      return [
        { text: "Datum", value: "date" },
        { text: "Zeit", value: "time" },
        {
          text: "Level",
          value: "level",
        },
        { text: "Nachricht", value: "message" },
      ];
    },
  },
  methods: {
    getChipColor(level) {
      if (level === "Info") {
        return "info";
      }
      if (level === "Warnung") {
        return "warning";
      }
      if (level === "Fehler") {
        return "error";
      }
      if (level === "Erfolg") {
        return "success";
      }
    },
    formatDate(value) {
      const date = new Date(value);
      if (date == "Invalid Date") {
        return "–";
      }
      return date.toLocaleString("de-CH", {
        dateStyle: "full",
      });
    },
    formatDateShort(value) {
      const date = new Date(value);
      if (date == "Invalid Date") {
        return "–";
      }
      return date.toLocaleString("de-CH", {
        dateStyle: "medium",
      });
    },
  },
});
</script>
